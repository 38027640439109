
/*=========================================================================*/

/* [Global Variables] - http://sass-lang.com/guide */

$color-purple: rgb(199, 48, 145);
$color-blue-dark: rgb(25, 41, 88);
$color-grey-dark: rgb(89, 89, 91);
$color-grey-med: rgb(109, 110, 113);
$color-grey-light: rgb(241, 242, 242);

$color-grey-light-pdf: rgb(201, 202, 202);

$font-default: 'Open Sans', sans-serif;

/*=========================================================================*/
